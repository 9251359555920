import React, { useState, useEffect, useRef } from "react";

//檢查是否可以載入avif 不能的話就使用預設圖片
const CheckImageFormat = ({ avifImage, defaultImage, ...props }) => {
  const [imageSrc, setImageSrc] = useState(defaultImage);
  const imgRef = useRef(null);

  useEffect(() => {
    // 如果沒有提供 avif 圖片，直接使用預設圖片
    if (!avifImage) {
      setImageSrc(defaultImage);
      return;
    }

    // 如果已經有實例，重複使用
    if (!imgRef.current) {
      imgRef.current = new Image();
    }

    const img = imgRef.current;

    const handleLoad = () => {
      setImageSrc(avifImage);
    };

    const handleError = () => {
      setImageSrc(defaultImage);
    };

    img.onload = handleLoad;
    img.onerror = handleError;
    img.src = avifImage;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [avifImage, defaultImage]);

  // 組件卸載時清理
  useEffect(() => {
    return () => {
      if (imgRef.current) {
        imgRef.current.onload = null;
        imgRef.current.onerror = null;
        imgRef.current = null;
      }
    };
  }, []);

  return <img src={imageSrc} {...props} />;
};

export default CheckImageFormat;
